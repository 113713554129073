import React, { useEffect, useState } from "react";
import BaseModalAnimated from "../../components/modal/BaseModalAnimated";
import CustomButton from "../../components/form/CustomButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomInput from "../../components/form/CustomInput";
import { showFormErrors } from "../../helpers/showFormErrors";
import CustomSelect from "../../components/form/CustomSelect";
import { getStatuses } from "../../helpers/configRoutes/backend_helper";
import GoogleMapsStops from "../../components/maps/GoogleMapsStops";

const AddStopsModal = ({ modal, setModal, validation }) => {
    const [statuses, setStatuses] = useState([])
    const validationStops = useFormik({
        initialValues: {
            name: '',
            order: (validation.values.stops.length + 1),
            status: {},
            status_id: '',
            mile: '',
            lat: 37.7749,
            lng: -122.4194,
            date:'',
            load_time:'',
            delivery_date:'',
            delivery_time:'',
            geofence:1,
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Name is required'),
            order: Yup.string().required('Order is required'),
            status_id: Yup.string().required('Status is required'),
            date: Yup.date()
                .required("Load date is required")
                .typeError("Invalid date format")
                .test('valida-date', 'The loading date must be greater than the last recorded delivery date', (value) => {
                    const previousStop = validation.values?.stops.find(stop => stop.order === validationStops.values.order - 1);
                    if(previousStop){
                        const previousDeliveryDateTime = new Date(`${previousStop.delivery_date}T${previousStop.delivery_time}`);
                        const currentLoadDateTime = new Date(`${value}`);
                       
                        return currentLoadDateTime > previousDeliveryDateTime;
                    }
                    return true
                }),
            load_time: Yup.string().required("Load time is required"),
            delivery_date: Yup.date()
                .required("Delivery date is required")
                .min(Yup.ref('date'), "Delivery date cannot be before load date")
                .typeError("Invalid date format"),
            delivery_time: Yup.string().required("Delivery time is required"),
            geofence: Yup.number()
        }),
        onSubmit: async (values, { setErrors, resetForm }) => {
            const lastArray = validation.values.stops;
            let newArray = [...lastArray, values];

            // Ordena el array por el campo `order` en orden ascendente
            newArray.sort((a, b) => a.order - b.order);
            // Ajusta los valores de `order` para que sean únicos
            newArray = newArray.map((item, index) => ({
                ...item,
                order: index + 1  // Asigna valores únicos y consecutivos a `order`
            }));
            // Actualiza el array `stops` en Formik con el array ordenado y ajustado
            validation.setFieldValue('stops', newArray);
            // Cierra el modal
            setModal({ ...modal, show: false });
            // Resetea el formulario después de guardar
            resetForm();
        }
    });

    useEffect(() => {
        const getCatalogs = async () => {
            const statusesResponse = await getStatuses({ params: { model: 'Stop' } });
            setStatuses(statusesResponse.statuses); // Descomentar si deseas actualizar los statuses de la API
        };
        getCatalogs();
    }, []);

    useEffect(() => {
        console.log('ok')
        if (!validation.values.stops || validation.values.stops.length === 0) {
            validationStops.setFieldValue('mile', 0);
        }
    }, [validationStops.values.order]);

    return (
        <>
            <BaseModalAnimated modal={modal} setModal={setModal}>
                <form onSubmit={e => {
                    e.preventDefault();
                    validationStops.handleSubmit();
                    showFormErrors(validationStops);
                    return false;
                }}>
                    <div className="grid grid-cols-1 gap-4 p-2">
                        <GoogleMapsStops stops={validation.values.stops} setStops={validation} validation={validationStops} />
                    </div>
                    <div className="grid grid-cols-2 gap-4 p-2">
                        <CustomInput
                            label="Order"
                            type="number"
                            name="order"
                            readOnly={true}
                            labelClassName="text-[14px] text-[#ADADAD] whitespace-nowrap"
                            error={validationStops?.errors?.order}
                            value={validationStops?.values?.order}
                            handleChange={validationStops.handleChange}
                        />
                        <CustomSelect
                            label="Status"
                            options={statuses}
                            name="status_id"
                            labelClassName={'text-base text-[#ADADAD]'}
                            className={'mr-2'}
                            error={validationStops?.errors?.status_id}
                            value={validationStops?.values?.status_id}
                            handleChange={(e) => {
                                const selectedStatus = statuses.find((s) => s.id === parseInt(e.target.value, 10));
                                validationStops.setFieldValue('status', selectedStatus);
                                validationStops.setFieldValue('status_id', e.target.value);
                            }}
                        />
                        <CustomInput
                            label="Mile"
                            type="text"
                            name="mile"
                            labelClassName="text-[14px] text-[#ADADAD] whitespace-nowrap"
                            error={validationStops?.errors?.mile}
                            value={validationStops?.values?.mile}
                            handleChange={validationStops.handleChange}
                        />
                        <CustomInput
                            label="Geofence"
                            type="text"
                            name="geofence"
                            labelClassName="text-[14px] text-[#ADADAD] whitespace-nowrap"
                            error={validationStops?.errors?.geofence}
                            value={validationStops?.values?.geofence}
                            handleChange={validationStops.handleChange}
                        />
                        <div className="grid grid-cols-2 ">
                            <CustomInput
                                label="Load Date"
                                name="date"
                                type={'date'}
                                labelClassName="text-[14px] text-[#ADADAD] whitespace-nowrap"
                                error={validationStops?.errors?.date}
                                value={validationStops?.values?.date}
                                handleChange={validationStops.handleChange}
                            />
                            <CustomInput
                                label="Load Time"
                                name="load_time"
                                type={'time'}
                                labelClassName="text-[14px] text-[#ADADAD] whitespace-nowrap"
                                error={validationStops?.errors?.load_time}
                                value={validationStops?.values?.load_time}
                                handleChange={validationStops.handleChange}
                            />
                        </div>
                        <div className="grid grid-cols-2 ">
                            <CustomInput
                                label="Delivery Date"
                                name="delivery_date"
                                type={'date'}
                                labelClassName="text-[14px] text-[#ADADAD] whitespace-nowrap"
                                error={validationStops?.errors?.delivery_date}
                                value={validationStops?.values?.delivery_date}
                                handleChange={validationStops.handleChange}
                            />
                            <CustomInput
                                label="Delivery Time"
                                name="delivery_time"
                                type={'time'}
                                labelClassName="text-[14px] text-[#ADADAD] whitespace-nowrap"
                                error={validationStops?.errors?.delivery_time}
                                value={validationStops?.values?.delivery_time}
                                handleChange={validationStops.handleChange}
                            />
                        </div>
                    </div>
                    <div className="flex items-center justify-center p-6 rounded-b border-slate-200">
                        <CustomButton
                            type={"success"}
                            className="w-full lg:w-[30%]"
                        >
                            Add Stop
                        </CustomButton>
                    </div>
                </form>
            </BaseModalAnimated >
        </>
    );
};
export default AddStopsModal;
