import CustomButton from "../../components/form/CustomButton";

export const Columns = (openModalAdd, setModalConfirm, modalConfirm, modalFiles, setModalFiles, completeFinishLoad,loadinStatus) => {
    

    let columns = [
        {
            id: 'order',
            name: 'WORK ORDER',
            selector: row => `${row.id}${row.client?.id}`,
        },
        {
            id: 'truck_triler',
            name: 'TRUCK TRAILER',
            selector: row => row.truck_triler,
        },
        {
            id: 'client',
            name: 'CLIENT',
            selector: row => row.client?.company_name,
        },
        {
            id: 'pick_up',
            name: 'PICK UP DATE',
            selector: row => row.pick_up_date,
        },
        {
            id: 'delivery_date',
            name: 'DELIVERY DATE',
            selector: row => row.delivery_date,
        },
        {
            id: 'evidences',
            name: 'EVIDENCES',
            cell: row => (
                <>
                    <CustomButton type="button" onClick={() => setModalFiles({ ...modalFiles, show: true, item: row })} className={`flex rounded-full w-full border-r items-center justify-center px-4 py-2 hover:bg-gray-100 bg-[#ccf0eb]`}>
                        Files
                    </CustomButton>
                </>
            ),
        },
        {
            id: 'action',
            name: 'ACTION',
            cell: row => (
                <>
                    {row.can.finish &&
                        <CustomButton loading={loadinStatus} textLoad={'Processing'} title='The operator finished all stops' type="button" onClick={() => completeFinishLoad(row.id,29)} className={`flex rounded-full w-full border-r items-center justify-center px-2 py-1 hover:bg-gray-100 bg-[#ccf0eb]`}>
                            Finished &nbsp;&nbsp;&nbsp;
                        </CustomButton>
                    }
                    {row.can.complete &&
                        <CustomButton loading={loadinStatus} textLoad={'Processing'} type="button" onClick={() => completeFinishLoad(row.id,30)} className={`flex rounded-full w-full border-r items-center justify-center px-2 py-1 hover:bg-gray-100 bg-[#e0d4fc]`}>
                            Completed
                        </CustomButton>
                    }
                </>
            ),
        },

        {
            id: 'status',
            name: 'STATUS',
            cell: row => (
                <span style={{ background: row.status?.color }} className={`px-2 py-1 rounded-full text-white`}>
                    {row.status?.name}
                </span>
            ),
        },
        {
            id: 'actions',
            name: '',
            button: true,
            //Aqui van botones como eliminar o editar
            cell: row => (
                <div className="flex items-center space-x-2">
                    {/* edit button */}
                    <button onClick={() => openModalAdd(row)} className="flex items-center justify-center px-4 py-2 border-r hover:bg-gray-100">
                        <i className="fas fa-pen text-gray-500"></i>
                    </button>
                    {/* delete button */}
                    <button onClick={() => setModalConfirm({ ...modalConfirm, show: true, id: row.id })} className={`flex border-r items-center justify-center px-4 py-2 ${!row.can?.delete ? '' : 'hover:bg-gray-100'}`} disabled={!row.can?.delete}>
                        <i className={`fas fa-trash ${!row.can?.delete ? 'opacity-50' : 'text-red-500'}`}></i>
                    </button>
                </div>
            ),
        },
    ];
    return columns;
}
